import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import AppRouter from "./components/AppRouter/AppRouter";
import MainPage from "./Pages/MainPage/MainPage";
import MainPageEng from "./Pages/MainPageEng/MainPageEng";

function App() {
  return (
    <HelmetProvider>
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<AppRouter />}>
          <Route index element={<MainPage />} />
          <Route path="/en/"  element={<MainPageEng />} />
          </Route>
        </Routes>
      </Router>
    </div>
    </HelmetProvider>
  );
}

export default App;
