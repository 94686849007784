import React from 'react'
import { Outlet } from 'react-router-dom'
import CookieModal from '../CookieModal/CookieModal'
import Header from '../Header/Header'
import './AppRouter.css'

const AppRouter = () => {
  return (
    <div className="global__container">
        <Header />
        <main className="main">
            <Outlet />
        </main>
    </div>
  )
}

export default AppRouter