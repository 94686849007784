import './FormSucsessful.css'

import React, { useState, useEffect } from "react";
import "./FormSucsessful.css";
import { useSelector } from "react-redux";

import { useDispatch } from "react-redux";
import closeIcon from '../../assets/icons/CloseIcon.png'
import { setClearIsFormSended } from '../../redux/ModalSlice/ModalSlice';

function FormSucsessful() {
  const response = useSelector((state) => state.modal.isFormSended);
  const [isActive, setIsActive] = useState(false);
  const dispatch = useDispatch();
  let timeoutId;

  useEffect(() => {
    setIsActive(response);

    if (isActive) {
      timeoutId = setTimeout(() => {
        dispatch(setClearIsFormSended());
      }, 10000);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [response, isActive, dispatch]);

  const handleCloseForm = () => {
    dispatch(setClearIsFormSended());
  }

  return (
    <div className={`form__sucsessful-container ${isActive ? "active" : ""}`}>
      <div className="form__sucsessful">
        <img onClick={handleCloseForm} src={closeIcon} alt="закрити повідомлення про успішну відправку форми" className="form__close-icon" />
        <div className="response__texts">
          <p className="response__title mont-m">
            Форма успішно відправлена!
          </p>
          <p className="response__description mont-r-21">
            Дякуємо за довіру! Ми зв’яжемося з вами найближчим часом.
          </p>
        </div>
      </div>
    </div>
  );
}

export default FormSucsessful;
