import "./Form.css";
import React from "react";
import { useState } from "react";
import { useTelegramMessage } from "../../utils/request";

const Form = ({title, formText, FormTextTwo, min, namePlaceholder, numberPlaceholder, messagePlaceholder, send}) => {
    const [userName, setUserName] = useState("");
    const [userPhone, setUserPhone] = useState("");
    const [userMessage, setUserMessage] = useState("");
    const [nameError, setNameError] = useState(false);
    const [PhoneError, setPhoneError] = useState(false);
    const { sendTelegramMessage } = useTelegramMessage();


    const handlePhoneNumberChange = (e) => {
        setUserPhone(e.target.value);
        setPhoneError(false);
      };

      const handleNameChange = (e) => {
        setUserName(e.target.value);
        setNameError(false);
      };

      const handleMessageChange = (e) => {
        setUserMessage(e.target.value);
      };

      const handleSubmit = async (e) => {
        e.preventDefault();
    
        let nameErrorFlag = false;
        let phoneErrorFlag = false;
    
        if (userName.length < 1) {
          setNameError(true);
          nameErrorFlag = true;
        } else {
          setNameError(false);
        }
    
        if (userPhone.length > 8 && !userPhone.includes("_")) {
          setPhoneError(false);
        } else {
          setPhoneError(true);
          phoneErrorFlag = true;
        }
    
        if (nameErrorFlag || phoneErrorFlag) {
          return;
        }
    
        if (!nameErrorFlag && !phoneErrorFlag) {
          const message = `\u{2708} Заявка з сайту \u{2708}\n Ім'я: ${userName}\n Номер телефону: ${userPhone}\n Повідомлення: ${
            userMessage || "Без повідомлення"
          }`;
    
          await sendTelegramMessage({ userName, userPhone, message });
    
          setUserName("");
          setUserPhone("");
          setUserMessage("");
        }
      };

  return (
    <section id="form" className="form__section">
      <div className="border__form"></div>
      <div className="form__wrapper">
        <div className="form__elipse"></div>
        <div className="form__texts">
          <h2 className="form__title mont-m">{title}</h2>
          <h3 className="form__description mont-r-n">
           {formText}
          </h3>
          <p className="form__description mont-r-n">
           {FormTextTwo}
            <span className="blue-color">{min}</span>
          </p>
        </div>
        <form onSubmit={handleSubmit} className="form">
          <input
            placeholder={namePlaceholder}
            value={userName}
            onChange={handleNameChange}
            className={`main__form-input ${
              nameError ? "error" : ""
            } mont-r-21`}
            type="text"
          />
          <input
            value={userPhone}
            onChange={handlePhoneNumberChange}
            placeholder={numberPlaceholder}
            className={`main__form-input ${
              PhoneError ? "error" : ""
            } mont-r-21`}
            type="number"
          />
          <input
            placeholder={messagePlaceholder}
            className="main__form-input mont-r-21"
            type="text"
            onChange={handleMessageChange}
            value={userMessage}
          />
          <button onClick={handleSubmit} className="form__button mont-m">{send}</button>
        </form>
      </div>
    </section>
  );
};

export default Form;
