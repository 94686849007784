import { useState } from "react";
import { useEffect } from "react";
import "./CookieModal.css";

const CookieModal = ({text, accept, decline}) => {
  const isAccepted = localStorage.getItem("cookieAccept");
  const [isCookieClosed, setIsCookieClosed] = useState(isAccepted);

  useEffect(() => {
    const isAccepted = localStorage.getItem("cookieAccept");
    if (isAccepted) {
      return;
    } else if (!isAccepted) {
      setIsCookieClosed(false);
    }
  }, [isAccepted]);

  const handleAcceptCookie = () => {
    setIsCookieClosed(true)
    localStorage.setItem("cookieAccept", true)
  }

  return <div className={`cookie__modal ${isCookieClosed ? "" : "active"}`}>
    <div className="cookie__modal-wrapper">
      <div className="cookie__modal-elipse"></div>
      <p className="cookie-text mont-m">{text}</p>
      <div className="cookie-buttons">
        <button onClick={handleAcceptCookie} className="cookie__button mont-m">{decline}</button>
        <button onClick={handleAcceptCookie} className="cookie__button right mont-m">{accept}</button>
      </div>
    </div>
  </div>;
};

export default CookieModal;
