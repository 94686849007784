import "./Footer.css";
import React from "react";
import logo from "../../assets/images/logoPC.png";
import logoMobile from "../../assets/images/logoMobile.png";

const Footer = () => {
  const isMobile = window.innerWidth <= 1199;

  return (
    <footer className="footer">
      <div className="footer__frapper">
        <img
          src={isMobile ? logoMobile : logo}
          alt="логотип adspro"
          className="footer__logo"
        />
        <div className="footer__contacts">
          <a className="footer__contact mont-m" href="mailto:advertisingpronet@gmail.com">
          advertisingpronet@gmail.com
          </a>
          <a className="footer__contact mont-m" href="tel:+380507031848">
            +380(50)-703-18-48
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
