import React from "react";
import "./MainPageEng.css";
import AboutUs from "../../components/AboutUs/AboutUs";
import Cover from "../../components/Cover/Cover";
import ServicesSection from "../../components/ServicesSection/ServicesSection";
import Results from "../../components/Results/Results";
import { sliderArrEn, sliderArrEnMobile } from "../../utils/imagesArr";
import Form from "../../components/Form/Form";
import Footer from "../../components/Footer/Footer";
import { useEffect } from "react";
import FormSucsessful from "../../components/FormSucsessful/FormSucsessful";
import { Helmet } from "react-helmet-async";
import CookieModal from "../../components/CookieModal/CookieModal";

const MainPageEng = () => {
  useEffect(() => {
    localStorage.setItem("language", "en");
  }, []);

  const isMobile = window.innerWidth <= 1199;

  return (
    <>
      <Helmet>
        <html lang="en"></html>

        <title>
          Expert Digital Advertising Audits – SEO audit, Google Ads audit &
          UI/UX Solutions
        </title>
        <meta
          name="description"
          content="Boost your digital presence with professional audits for SEO audit, Google Ads audit, and UI/UX design solutions. Maximize ROI with tailored insights and actionable strategies. Contact us today!"
        />
        <meta property="og:url" content={"https://www.advertisingpro.net/en"} />
        <link rel="canonical" href={"https://www.advertisingpro.net/en"} />
        <meta
          property="og:description"
          content="Boost your digital presence with professional audits for SEO audit, Google Ads audit, and UI/UX design solutions. Maximize ROI with tailored insights and actionable strategies. Contact us today!"
        />
        <meta
          property="og:title"
          content="Expert Digital Advertising Audits – SEO audit, Google Ads audit & UI/UX Solutions"
        />
         <meta property="og:image" content="%PUBLIC_URL%/screenshot.png" />
        <script>{`
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-MPF8PDBJ')
      `}</script>
      <meta name="google-adsense-account" content="ca-pub-2130755164556695"></meta>
      </Helmet>
      <div className="main__page">
        <Cover
          title={"Google ADS, SEO, UI/UX Audits"}
          description={"We find growth points to make you stronger."}
          cardTitleOne={"Custom Solutions"}
          cardTextOne={
            "Each audit is tailored to meet the specific needs of your business."
          }
          cardTitleTwo={"Maximum Transparency"}
          cardTextTwo={
            "We provide a comprehensive report with clear and understandable metrics, data, and insights."
          }
          cardTitleThree={"Proven Results"}
          cardTextThree={
            "Our clients have already boosted their sales and optimized their costs thanks to our audits."
          }
        />
        <AboutUs
          title={"About company"}
          textTitle={
            "We bring together the skills and experience of analysts, marketers, and designers who are passionate about their work."
          }
          textOne={
            "We don’t just analyze data; we deliver actionable solutions based on data insights and market knowledge."
          }
          textTwo={
            "We approach projects with meticulous attention to detail and focus on results that genuinely benefit your business. Together, we identify weaknesses, turn them into growth opportunities, and unlock new avenues for your success."
          }
          textThree={
            "At the end of each audit, you will receive a comprehensive file that includes analysis, detailed comments, and actionable steps to enhance your business performance."
          }
        />
        <ServicesSection
          title={"Our services"}
          decorOne={"PPC"}
          cardTitleOne={"PPC Site Audit"}
          cardTextOne={
            "We identify how effectively the budget is being used, analyze keywords, marketing campaign settings, strategies, and results to improve ROI, reduce conversion costs, and optimize ad performance."
          }
          CardPriceOne={"Price: 100$"}
          decorTwo={"SEO"}
          cardTitleTwo={"SEO Site Audit"}
          cardTextTwo={
            "We conduct an in-depth analysis of the site for compliance with search engine requirements. We identify technical errors, analyze content and keywords to increase the site's visibility in search results and attract more traffic."
          }
          CardPriceTwo={"Price: 100$"}
          decorThree={"Ui/Ux"}
          cardTitleThree={"Ui/Ux Site Audit"}
          cardTextThree={
            "We analyze the usability and effectiveness of your site's user interface. We identify barriers that hinder conversion and suggest improvements that will make interaction with the product intuitive, enjoyable, and productive."
          }
          CardPriceThree={"Price: 100$"}
          buttonText={"Submit an application"}
        />
        <Results
          title={"The numbers will speak for us"}
          sliderArr={isMobile ? sliderArrEnMobile : sliderArrEn}
        />
        <Form
          title={"Contact us"}
          formText={
            "Enhance the results of your advertising campaigns and optimize your website for search engines. Leave a request for an audit, and we will help you reach new heights in digital marketing!"
          }
          FormTextTwo={"We will contact you within"}
          min={" 15 minutes!"}
          namePlaceholder={"Your name"}
          numberPlaceholder={"Phone number"}
          messagePlaceholder={"Message (optional)"}
          send={"Send"}
        />
        <Footer />
        <FormSucsessful />
        <CookieModal
          text={"This site uses cookies to improve the user experience"}
          accept={"Accept"}
          decline={"Decline"}
        />
      </div>
      {/* <!-- Google Tag Manager (noscript) --> */}
      <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-MPF8PDBJ"
          height="0"
          width="0"
          style="display:none;visibility:hidden"
        ></iframe>
      </noscript>
      {/* <!-- End Google Tag Manager (noscript) --> */}
    </>
  );
};

export default MainPageEng;
