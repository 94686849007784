import './ServicesSection.css'
import React from 'react'

const ServicesSection = ({title, decorOne, cardTitleOne, cardTextOne, CardPriceOne, decorTwo, cardTitleTwo, cardTextTwo, CardPriceTwo, decorThree, cardTitleThree, cardTextThree, CardPriceThree, buttonText}) => {

    const handleScrollClick = (sectionId) => {
        const section = document.getElementById(sectionId);
        const yOffset = -90;
        const yPosition = section.getBoundingClientRect().top + window.pageYOffset + yOffset;
    
        
        window.scrollTo({
          top: yPosition,
          behavior: 'smooth'
        });
      };

  return (
    <section id='services' className='services'>
        <h2 className="services__title mont-m">{title}</h2>
        <div className="services__border"></div>
        <div className="services__wrapper">
            <div className="services__elipse"></div>
            <div className="service">
                <div className="service__decoration">
                    <h3 className="service__decoration-title mont-sb">{decorOne}</h3>
                </div>
                <h3 className="service-title mont-r-n">{cardTitleOne}</h3>
                <p className="service__text mont-r-21">{cardTextOne}</p>
                <p className="service__price mont-r-21">{CardPriceOne}</p>
                <button onClick={() => handleScrollClick('form')} className="service__button mont-m">{buttonText}</button>
            </div>
            <div className="service">
                <div className="service__decoration">
                    <h3 className="service__decoration-title mont-sb">{decorTwo}</h3>
                </div>
                <h3 className="service-title mont-r-n">{cardTitleTwo}</h3>
                <p className="service__text mont-r-21">{cardTextTwo}</p>
                <p className="service__price mont-r-21">{CardPriceTwo}</p>
                <button onClick={() => handleScrollClick('form')} className="service__button mont-m">{buttonText}</button>
            </div>
            <div className="service">
                <div className="service__decoration">
                    <h3 className="service__decoration-title mont-sb">{decorThree}</h3>
                </div>
                <h3 className="service-title mont-r-n">{cardTitleThree}</h3>
                <p className="service__text mont-r-21">{cardTextThree}</p>
                <p className="service__price mont-r-21">{CardPriceThree}</p>
                <button onClick={() => handleScrollClick('form')} className="service__button mont-m">{buttonText}</button>
            </div>
        </div>
    </section>
  )
}

export default ServicesSection