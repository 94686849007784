import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./Header.css";
import logo from "../../assets/images/logoPC.png";
import logoMobile from "../../assets/images/logoMobile.png";

const Header = () => {
  const [language, setLanguage] = useState("ua");
  const isMobile = window.innerWidth <= 1199;

  useEffect(() => {
    const savedLanguage = localStorage.getItem("language");
    if (savedLanguage === "ua" || savedLanguage === "en") {
      setLanguage(savedLanguage);
    } else {
      setLanguage("ua");
    }
  });

  const handleLanguageChange = (lang) => {
    setLanguage(lang);
    localStorage.setItem("language", lang);
  };

  const handleScrollClick = (sectionId) => {
    const section = document.getElementById(sectionId);
    const yOffset = -90;
    const yPosition = section.getBoundingClientRect().top + window.pageYOffset + yOffset;

    
    window.scrollTo({
      top: yPosition,
      behavior: 'smooth'
    });
  };


  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const translations = {
    ua: {
      about: "Про нас",
      services: "Послуги",
      results: "Результати",
      consultation: "Консультація",
    },
    en: {
      about: "About us",
      services: "Services",
      results: "Results",
      consultation: "Consultation",
    },
  };

  return (
    <header className="header">
      <div className="header__whapper">
        <img
          onClick={scrollToTop}
          src={isMobile ? logoMobile : logo}
          alt="логотип adspro"
          className="header__logo"
        />
        <nav className="header__nav">
          <p onClick={() => handleScrollClick('about')} className="header__nav-item mont-r-n">
            {translations[language].about}
          </p>
          <p onClick={() => handleScrollClick('services')} className="header__nav-item mont-r-n">
            {translations[language].services}
          </p>
          <p onClick={() => handleScrollClick('results')} className="header__nav-item mont-r-n">
            {translations[language].results}
          </p>
        </nav>
        <div className="header__buttons">
          <div className="language__buttons">
            <Link
              to="/en"
              className={`language__button mont-r-n ${
                language === "en" ? "active" : ""
              }`}
              onClick={() => handleLanguageChange("en")}
            >
              Eng
            </Link>
            <div className="language__border"></div>
            <Link
              to="/"
              className={`language__button mont-r-n ${
                language === "ua" ? "active" : ""
              }`}
              onClick={() => handleLanguageChange("ua")}
            >
              Ua
            </Link>
          </div>
          <div className="language__buttons-mobile">
            {language === "ua" ? (
              <Link
                to="/en"
                className="language__button mont-r-n"
                onClick={() => handleLanguageChange("en")}
              >
                Eng
              </Link>
            ) : (
              <Link
                to="/"
                className="language__button mont-r-n"
                onClick={() => handleLanguageChange("ua")}
              >
                Ua
              </Link>
            )}
          </div>
          <button onClick={() => handleScrollClick('form')}  className="header__target-button mont-r-n">
            {translations[language].consultation}
          </button>
        </div>
      </div>
    </header>
  );
};

export default Header;
