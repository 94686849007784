import slider1 from "../assets/images/slider1.webp";
import slider2 from "../assets/images/slider2.webp";
import slider3 from "../assets/images/slider3.webp";
import slider4 from "../assets/images/slider4.webp";
import sliderEn1 from "../assets/images/sliderEn1.webp";
import sliderEn2 from "../assets/images/sliderEn2.webp";
import sliderEn3 from "../assets/images/sliderEn3.webp";
import sliderEn4 from "../assets/images/sliderEn4.webp";

import slider1Mobile from "../assets/images/slider1Mobile.webp";
import slider2Mobile from "../assets/images/slider2Mobile.webp";
import slider3Mobile from "../assets/images/slider3Mobile.webp";
import slider4Mobile from "../assets/images/slider4Mobile.webp";
import sliderEn1Mobile from "../assets/images/sliderEn1Mobile.webp";
import sliderEn2Mobile from "../assets/images/sliderEn2Mobile.webp";
import sliderEn3Mobile from "../assets/images/sliderEn3Mobile.webp";
import sliderEn4Mobile from "../assets/images/sliderEn4Mobile.webp";

export const sliderArr = [slider1, slider2, slider3, slider4];

export const sliderArrEn = [sliderEn1, sliderEn2, sliderEn3, sliderEn4];

export const sliderArrMobile = [
  slider2Mobile,
  slider1Mobile,
  slider3Mobile,
  slider4Mobile,
];

export const sliderArrEnMobile = [
  sliderEn2Mobile,
  sliderEn1Mobile,
  sliderEn3Mobile,
  sliderEn4Mobile,
];
