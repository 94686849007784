import React, { useEffect } from 'react'
import './MainPage.css'
import AboutUs from '../../components/AboutUs/AboutUs'
import Cover from '../../components/Cover/Cover'
import ServicesSection from '../../components/ServicesSection/ServicesSection'
import Results from '../../components/Results/Results'
import { sliderArr, sliderArrMobile } from '../../utils/imagesArr'
import Form from '../../components/Form/Form'
import Footer from '../../components/Footer/Footer'
import FormSucsessful from '../../components/FormSucsessful/FormSucsessful'
import { Helmet } from 'react-helmet-async'
import CookieModal from '../../components/CookieModal/CookieModal'

const MainPage = () => {

  useEffect(() => {
    localStorage.setItem("language", "uk")
  }, [])

  const isMobile = window.innerWidth <= 1199;

  
  return (
    <>
        <Helmet>
        <html lang="uk"></html>
        <title>Експертний аудит цифрової реклами – SEO аудит, Google Ads аудит і UI/UX дизайн рішення</title>
        <meta name="description" content="Підвищуйте свою цифрову присутність завдяки професійним аудитам SEO, Google Ads та UI/UX дизайну. Максимізуйте ROI з індивідуальними рішеннями. Зв’яжіться з нами вже сьогодні!"/>
        <meta property="og:url" content={"https://www.advertisingpro.net/"} />
        <link rel="canonical" href={"https://www.advertisingpro.net/"} />
        <meta
      property="og:description"
      content="Підвищуйте свою цифрову присутність завдяки професійним аудитам SEO, Google Ads та UI/UX дизайну. Максимізуйте ROI з індивідуальними рішеннями. Зв’яжіться з нами вже сьогодні!"
    />
        <meta
      property="og:title"
      content="Експертний аудит цифрової реклами – SEO аудит, Google Ads аудит і UI/UX дизайн рішення"
    />
     <meta property="og:image" content="%PUBLIC_URL%/screenshot.png" />
      <script>{`
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-MPF8PDBJ')
      `}</script>
      <meta name="google-adsense-account" content="ca-pub-2130755164556695"></meta>
      </Helmet>
    <div className='main__page'>
        <Cover title={"Google ADS, SEO, UI/UX Аудити"} description={"Ми знаходимо точки зростання, щоб ви ставали сильнішими."} cardTitleOne={"Індивідуальні рішення"} cardTextOne={"Кожен аудит адаптовано під потреби саме вашого бізнесу."} cardTitleTwo={"Максимальна прозорість"} cardTextTwo={"Повний звіт з конкретними та зрозумілими метриками, даними та висновками."} cardTitleThree={"Доведений результат"} cardTextThree={"Наші клієнти вже збільшили свої продажі та оптимізували витрати завдяки нашим аудитам."} />
        <AboutUs title={"Про компанію"} textTitle={'Наша команда - це навички та досвід аналітиків, маркетологів та дизайнерів, захоплених своєю роботою.'} textOne={"Ми не просто перевіряємо дані, а пропонуємо реальні рішення, засновані на аналізі даних та знаннях про ринок."} textTwo={"Ми підходимо до проектів з увагою до деталей та фокусуємося на результатах, які приносять реальну користь вашому бізнесу. Разом ми знаходимо слабкі місця, перетворюємо їх на точки зростання та відкриваємо нові можливості для вашого успіху."} textThree={"За результатами кожного аудиту у вас залишиться файл з аналізом, детальними коментарями, кроками до реалізації для покращення показників вашого бізнесу."} />
        <ServicesSection title={"Наші Послуги"} decorOne={"PPC"} cardTitleOne={"PPC Аудит сайту"} cardTextOne={"Виявляємо, наскільки ефективно використовується бюджет, аналізуємо ключові слова, налаштування рекламних кампаній, стратегії та результати, щоб покращити ROI та знизити вартість конверсії."} CardPriceOne={"Ціна: 100$"} decorTwo={"SEO"} cardTitleTwo={"SEO Аудит сайту"} cardTextTwo={"Проводимо глибокий аналіз сайту на відповідність вимогам пошукових систем. Виявляємо технічні помилки, аналізуємо контент та ключові слова, щоб збільшити видачу сайту та залучити більше трафіку."} CardPriceTwo={"Ціна: 100$"} decorThree={"Ui/Ux"} cardTitleThree={"Ui/Ux Аудит сайту"} cardTextThree={"Аналізуємо зручність та ефективність користувацького інтерфейсу вашого сайту. Пропонуємо покращення, які зроблять взаємодію з продуктом інтуїтивною, приємною та результативною."} CardPriceThree={"Ціна: 100$"}  buttonText={"Залишити заявку"} />
        <Results title={"Цифри скажуть за нас"} sliderArr={isMobile ? sliderArrMobile : sliderArr}/>
        <Form title={"Зв’яжіться з нами"} formText={"Покращте результати своїх рекламних кампаній та оптимізуйте сайт для пошукових систем. Залиште заявку на аудит, і ми допоможемо вам досягти нових висот!"} FormTextTwo={"Зв’яжемось з Вами протягом "} min={"15 хвилин!"} namePlaceholder={"Ваше ім’я"} numberPlaceholder={"Номер телефону"} messagePlaceholder={"Повідомлення(опціонально)"} send={"Відправити"} />
        <Footer />
        <FormSucsessful />
        <CookieModal text={"Цей сайт використовує файли сookie для покращення досвіду користувача "} accept={"Прийняти"} decline={"Відхилити"}/>
    </div>
        {/* <!-- Google Tag Manager (noscript) --> */}
        <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-MPF8PDBJ"
          height="0"
          width="0"
          style="display:none;visibility:hidden"
        ></iframe>
      </noscript>
      {/* <!-- End Google Tag Manager (noscript) --> */}
    </>
  )
}

export default MainPage