import './AboutUs.css'
import React from 'react'
import aboutImage from '../../assets/images/about__image.webp'

const AboutUs = ({title, textTitle, textOne, textTwo, textThree}) => {
  return (
    <section id='about' className='about__us'>
        <h2 className="about__us-title mont-m">{title}</h2>
        <div className="about__border"></div>
        <div className="about__wrapper">
          <div className="aboute__elipse"></div>
            <div className="about__texts">
                <h3 className="about__texts-title mont-r-n">{textTitle}</h3>
                <p className="about__texts-text mont-r-21">{textOne}</p>
                <p className="about__texts-text mont-r-21">{textTwo}</p>
                <p className="about__texts-text mont-r-21">{textThree}</p>
            </div>
            <img src={aboutImage} alt="на фото зображена робочий процес adspro" className="about__image" />
        </div>
    </section>
  )
}

export default AboutUs