import "./Results.css";
import React, { useState } from "react";
import arrowLeft from '../../assets/icons/arrowLeft.png'
import arrowRight from '../../assets/icons/arrowRight.png'

const Results = ({title, sliderArr }) => {
  const isMobile = window.innerWidth <= 1199;

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handlePrevButtonClick = () => {
    setCurrentImageIndex(
      (prevIndex) => (prevIndex - 1 + sliderArr.length) % sliderArr.length
    );
  };

  const handleNextButtonClick = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % sliderArr.length);
  };
  return (
    <section id="results" className="results">
      <h2 className="results__title mont-m">{title}</h2>
      <div className="results__border"></div>
      <div className="slider__result-current-image">
        <div
          className="slider__image-container"
          style={{
            transform: `translateX(-${
              currentImageIndex * (isMobile ? 320 : 1200)
            }px)`,
          }}
        >
          {sliderArr.map((img, index) => (
            <img
              key={index}
              src={img}
              alt={`слайдер фото ${index + 1}`}
              className="slider__image"
            />
          ))}
        </div>
      </div>
      <div className="slider__buttons">
        <div onClick={handlePrevButtonClick} className="slider__button">
            <img className="slider__arrow" src={arrowLeft} alt="прогорнути слайдер вліво" />
        </div>
        <div onClick={handleNextButtonClick} className="slider__button">
        <img className="slider__arrow" src={arrowRight} alt="прогорнути слайдер вправо" />
        </div>
      </div>
    </section>
  );
};

export default Results;
