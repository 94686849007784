import "./Cover.css";
import React from "react";
import coverImage from "../../assets/images/coverImage.webp";

const Cover = ({ title, description, cardTitleOne, cardTextOne, cardTitleTwo, cardTextTwo, cardTitleThree, cardTextThree,}) => {
  return (
    <section className="cover__wrapper">
      <div className="cover">
        <img
          src={coverImage}
          alt="adspro на зображенні гори"
          className="cover__image"
        />
        <h1 className="cover__title mont-m">{title}</h1>
        <h2 className="cover__description mont-m">
         {description}
        </h2>
        <div className="cover__benefits">
          <div className="cover__benefite">
            <h3 className="cover__benefite-title mont-m">{cardTitleOne}</h3>
            <p className="cover__benefite-description mont-r-21">{cardTextOne}</p>
          </div>
          <div className="cover__benefite">
            <h3 className="cover__benefite-title mont-m">{cardTitleTwo}</h3>
            <p className="cover__benefite-description mont-r-21">{cardTextTwo}</p>
          </div>
          <div className="cover__benefite">
            <h3 className="cover__benefite-title mont-m">{cardTitleThree}</h3>
            <p className="cover__benefite-description mont-r-21">{cardTextThree}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Cover;
